<template>
    <v-dialog
        persistent
        v-model="dialog"
        width="700"
        >
        <v-card>
            <v-toolbar color="primary" dark>
                ログイン
            </v-toolbar>
            <v-card-text>
                <div class="pt-3">
                    <v-text-field
                        v-model="username"
                        prepend-icon="mdi-account"
                        label="ユーザー名"
                        @keyup.enter="login()"
                        />
                    <v-text-field
                        v-model="password"
                        type="password"
                        prepend-icon="mdi-lock"
                        label="パスワード"
                        @keyup.enter="login()"
                        />
                </div>
            </v-card-text>
            <v-card-actions class="pb-5">
                <v-spacer></v-spacer>
                <div v-if="loginFailed" class="pr-5" style="color:red">ログインに失敗しました</div>
                <v-btn color="primary" :loading="loading" width="80" @click="login()">送信</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { YhtDuct } from '@/libs/yht'
import crypto from 'crypto'

export default {
    data: () => ({
        duct: new YhtDuct(),

        dialog: true,
        loading: false,
        username: "",
        password: "",
        loginFailed: false,
    }),
    methods: {
        hashPassword(password) {
            return crypto.createHash('sha256').update(password).digest('hex');
        },
        login() {
            this.loading = true;
            this.duct.send(
                this.duct.nextRid(),
                this.duct.EVENT.ACCOUNT_LOGIN,
                {
                    username: this.username,
                    password: this.hashPassword(this.password)
                }
            );
        }
    },
    created() {
        if(window.localStorage.getItem("YhtUsername") && window.localStorage.getItem("YhtPassword")) {
            window.location.href="/main";
        }
        this.duct.open("/ducts/wsd");
        this.duct.invokeOnOpen(() => {
            this.duct.addEventHandler(
                this.duct.EVENT.SCHEME_GET,
                {
                    success: (rid,eid,data) => {
                        this.duct = { ...this.duct, ...data };
                    }
                }
            );
            this.duct.send(this.duct.nextRid(), this.duct.EVENT.SCHEME_GET);

            this.duct.addEventHandler(
                this.duct.EVENT.ACCOUNT_LOGIN,
                {
                    success: () => {
                        window.localStorage.setItem("YhtUsername", this.username);
                        window.localStorage.setItem("YhtPassword", this.hashPassword(this.password));
                        window.location.href="/main";
                    },
                    error: (rid,eid,data) => {
                        switch(data.error_code){
                            case this.duct.ERROR.ACCOUNT_LOGIN.FAILED:
                                this.username = "";
                                this.password = "";
                                this.loginFailed = true;
                                break;
                        }
                    },
                    complete: () => {
                        this.loading = false;
                    }
                }
            );
        });
    }
}
</script>
